import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router"
import { addToCart } from "../../features/cartSlice";
import ProgressBar from "../ProgressBar"
import "./Home.scss"

import { useGetAllProductsQuery } from "../../features/productsApi";
import ProgressBarBox from "../ProgressBarBox/ProgressBarBox";

const Home = () => {
    const { data, error, isLoading } = useGetAllProductsQuery();
    const dispatch = useDispatch();
    const history = useNavigate();

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
        history("/cart")
    }

    const getCurrencyCol = (price) => {
        const formatter = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0, // Depending on whether you want to show decimal places or not
        }).format(price);
        return formatter
    }

    const goDetailItem = (id) => {
        history(`/detail-item/${id}`)
    }

    return ( 
        <div className="home-container">
            { isLoading ? 
                (
                    <p>Loading...</p>
                ) : error ? (
                    <p>An error occured</p> 
                ) : (
                    <>
                        <h2>Los recien llegados</h2>
                        <div className="products">
                            {data?.map((product, index) => 
                                <div key={product.id}  className="product">
                                    <div className="image-product">
                                        {/* <img src={`http://localhost:5001/${product.mainImage}`} alt={product.name} /> */}
                                        <img src={`https://shopping-card-backend-b6dx.onrender.com/${product.mainImage}`} alt={product.name} />
                                    </div>
                                    <div className="details">
                                        <h3 className="rethink-sans-regular">
                                            <a href="" onClick={() => goDetailItem(product.id)}>{product.name}</a>                                            
                                        </h3>
                                        <div className="detail-price">
                                            <span className="description">{product.desc}</span>
                                            <span className="container-price">
                                                <span className="amount-del rethink-sans-regular">
                                                    <span className="symbol-price"></span>{getCurrencyCol(product.off)}
                                                </span>

                                                <span className="amount-ins rethink-sans-regular">
                                                    <span className="symbol-price"></span>{getCurrencyCol(product.price)}
                                                </span>
                                            </span>
                                            {/* <div className="progress">
                                                <ProgressBar key={index} progressVal={(product.quantity-product.sold) / product.quantity * 100} quantity={product.quantity} sold={product.sold} />
                                            </div> */}
                                            <div className="progress">
                                                <ProgressBarBox key={index} progressVal={product.boxState} />
                                            </div>
                                            <button onClick={() => handleAddToCart(product)} >Add To Cart</button>
                                        </div>                                        
                                    </div>                                   
                                </div> 
                            )}
                        </div>
                    </>
                )
            }
        </div>
    );
}
 
export default Home;