import './App.scss';
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter, Route, Routes, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

import NavBar from './components/NavBar';
import Cart from './components/Cart';
import DetailItem from './components/DetailItem/DetailItem';
import Home from './components/Home/Home';
import NotFound from './components/NotFound';
import Checkout from './components/Checkout';
import ThankYouPage from './components/SuccessResponse';
import Payment from './components/Payment';

/* Tutorial Youtube: https://www.youtube.com/watch?v=3H3AQT4ZdDc&list=PL63c_Ws9ecIRnNHCSqmIzfsMAYZrN71L6 */

/* Tutorial Youtube 3 May: https://www.youtube.com/watch?v=zVGc8D4m9MA&list=PL63c_Ws9ecIRnNHCSqmIzfsMAYZrN71L6&index=16 */


function App () {

    const handleSuccessfulPayment = (paymentMethodId) => {
        console.log('Pago exitoso con ID de método de pago:', paymentMethodId);
        // Aquí puedes enviar el paymentMethodId a tu servidor para completar el pago
    };

    return <div className='App'>
        {/* <BrowserRouter basename="/shopping"> */}
        <BrowserRouter>
            <ToastContainer />
            <NavBar />
            <Routes>
                
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/cart" exact element={<Cart/>} />
                <Route path="/detail-item/:id" exact element={<DetailItem/>} />
                <Route path="/not-found" exact element={<NotFound/>} />
                <Route path="/thanks" exact element={<ThankYouPage/>} />
                <Route path="/payment" exact element={<Payment/>} />
                <Route path="/" exact element={<Home/>} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </div>
}

export default App;