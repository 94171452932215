import { useParams } from 'react-router-dom';
import { useGetAllProductsQuery } from "../../features/productsApi";
import "./DetailItem.scss";
import ProgressBarBox from "../ProgressBarBox/ProgressBarBox";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router"
import { addToCart } from "../../features/cartSlice";

import logoBold from "../../assets/images/bold.png";
import logoWhatsapp from "../../assets/images/logos--whatsapp-icon.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const DetailItem = () => {
    const { id } = useParams();
    const { data, error, isLoading } = useGetAllProductsQuery();
    const dispatch = useDispatch();
    const history = useNavigate();

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
        history("/cart")
    }

    if(isLoading) {
        return <p>Loading...</p>
    };

    if(error){
        return <p>Error loading products</p>;
    }

    if(!data) {
        return <p>No data available</p>
    }

    const item = data.find(item => item.id == parseInt(id));

    if(!item){
        return <p>Item not found</p>
    }

    console.log("DATA", item)

    if (!item) {
        return <p>Item not found</p>;
    }

    const formatToCOP = (amount) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
     };

    return (
        <div className='detail-content'>
            <div className='img-item'>
                <Carousel>
                {item.image.map((imgPath, index) => (
                    <div>
                        {/* <img src={`http://localhost:5001/${imgPath}`} /> */}
                        <img src={`https://shopping-card-backend-b6dx.onrender.com/${imgPath}`} />
                    </div>
                ))}
                </Carousel>
            </div>
            <div className='content-item'>
                <h4 className='brand'>{item.desc}</h4>
                <h3>{item.name}</h3>
                <span className="container-price">
                    <span className="amount-del rethink-sans-regular">
                        {formatToCOP(item.off)}
                    </span>
                    <ins aria-hidden="true">
                        <span className="amount-ins rethink-sans-regular">
                            {formatToCOP(item.price)}
                        </span>
                    </ins>
                </span>
                <span dangerouslySetInnerHTML={{ __html: item.desc2 }}></span>
                <div className="progress">
                    <ProgressBarBox key={item.id} progressVal={item.boxState} />
                </div>
                <button className="btn-add-to-cart"  onClick={() => handleAddToCart(item)} >Add To Cart</button>
                <div className='how-can-pay'>
                    <h5>¿Cómo lo puedo pagar?</h5>
                    <div className='payments'>
                        <div className='box-bold'>
                            <img src={logoBold} alt='logo pagos Bold' />                      
                            <p>Paga online con tarjeta débito y crédito.</p>
                        </div>
                        <div className='box-pay'>
                            <div className='bg-logo'>
                                <img src={logoWhatsapp} alt='logo pagos Whatsapp' />
                            </div>                            
                            <p>Finaliza y obten asesoría a través de whatsapp.</p>
                        </div>
                    </div>
                </div>                
            </div>
            
        </div>
    )
}

export default DetailItem
