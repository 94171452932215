import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productsApi = createApi({
    reducerPath: "productsApi",
    // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:5001"}),
    baseQuery: fetchBaseQuery({ baseUrl: "https://shopping-card-backend-b6dx.onrender.com"}),
    endpoints: (builder) => ({
        getAllProducts: builder.query({
            query: (id) => "products",
        }),
    }),
});

export const { useGetAllProductsQuery } = productsApi;